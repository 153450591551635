import React from 'react';
import classnames from 'classnames';

const Status = ({ status }) => {
  let color;

  if (['Scam', 'Hacked', 'Phishing'].includes(status)) color = '#D62839';

  if (['Suspicious'].includes(status)) color = '#FF7138';

  if (['Souls verified'].includes(status)) color = '#C2E900';

  if (['No info'].includes(status)) color = '#FFFFFF';

  if (['OpenSea verified'].includes(status)) color = 'rgba(64, 127, 219)';

  return (
    <div className={classnames("flex items-center w-fit py-1.5 px-2 rounded-lg text-[11px] leading-none border-current border bg-transparent")} style={{ color }}>
      {status === 'Scam' && <>Scam</>}
      {status === 'Hacked' && <>Hacked</>}
      {status === 'Phishing' && <>Phishing</>}
      {status === 'Souls verified' && <>Verified</>}
      {status === 'Suspicious' && <>Suspicious</>}
      {status === 'No info' && <>No info</>}
      {status === 'OpenSea verified' && <>Verified</>}
    </div>
  );
};

export default Status;