import React from 'react';
import { Debounce } from 'react-throttle';

const Search = ({ onChange }) => {
  return (
    <div className="relative">
      <Debounce time="200" handler="onChange">
        <input type="text" className="peer pl-4 py-4 pr-4 w-full xl:w-96 h-14 font-medium bg-dark rounded-lg placeholder:pt-0.5 placeholder:text-opacity-40 placeholder-shown:pl-14 focus:pl-4 focus:outline-none focus:placeholder:opacity-0 transition"
          placeholder="Project search"
          onChange={(e) => onChange(e.target.value)}
        />
      </Debounce>

      <span className="hidden peer-focus:hidden peer-placeholder-shown:block absolute top-4 left-4 opacity-40">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="11.059" cy="11.0619" r="7.059" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M20.0008 20.0027L16.0508 16.0527" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </span>
    </div>

  );
};

export default Search;