import React from 'react';
import Block from './Block';

const Vote = ({ vote }) => {
  if (['scam', 'hacked', 'fishing'].includes(vote)) return (
    <Block className="bg-[#D62839] text-white text-[11px]">
      {vote === 'scam' && <>Scam</>}
      {vote === 'hacked' && <>Hacked</>}
      {vote === 'fishing' && <>Phishing</>}
    </Block>
  );

  if (['suspicious_activity'].includes(vote)) return (
    <Block className="bg-[#FF7138] text-white text-[11px]">Suspicious</Block>
  );

  return (
    <Block className="bg-yellow text-[11px] text-black">Trusted</Block>
  )
};

export default Vote;