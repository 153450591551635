export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const parseData = (data) => {
  const parts = data.split('-');
  const year = parseInt(parts[0]);
  const month = parseInt(parts[1]) - 1;
  const day = parseInt(parts[2]);

  if (isNaN(year) || isNaN(month) || isNaN(day)) return data;

  return new Date(year, month, day);
};

export const convertToK = (num) => {
  const val = Number(num);

  if (val >= 10000) return (val / 1000).toFixed(1) + 'k';

  return val;
}

export const borderSquircle = (radiusX, radiusY) => {
  const loop = (radiusX, radiusY, counter, list) => {
    if (counter >= 0) {
      const angle = counter * (Math.PI / 180);
      const cosX = Math.cos(angle);
      const x = Math.pow(Math.abs(cosX), radiusX / 100) * 50 * (Math.abs(cosX + 0.0000000001) / (cosX + 0.0000000001)) + 50;

      const sinY = Math.sin(angle);
      const y = Math.pow(Math.abs(sinY), radiusY / 100) * 50 * (Math.abs(sinY + 0.0000000001) / (sinY + 0.0000000001)) + 50;

      const percentX = (x / 100) * 100;
      const percentY = (y / 100) * 100;

      const newList = percentX + "% " + percentY + "%, " + list;
      return loop(radiusX, radiusY, counter - 1, newList);
    } else {
      return "polygon(" + list + ")";
    }
  }

  return loop(radiusX, radiusY, 359, "100% 0");
}