import React from 'react';

import widthClass from './columnsWidth';

const HeaderList = () => {
  return (
    <div className="hidden xl:block sticky top-[63px] dark:bg-body-dark px-3 py-6 z-[2]">
      <div className='flex items-center md:space-x-5 xl:space-x-10 text-white/60 text-sm font-medium leading-none'>
        <div className={
          widthClass[0]
        }>User</div>
        <div className={
          widthClass[1]
        }>Role</div>
        <div className={
          widthClass[2]
        }>Vote</div>
        <div className={
          widthClass[3]
        }>Project</div>
        <div className={
          widthClass[4]
        }>Project status</div>
        <div className={
          widthClass[5]
        }>Time</div>
        <div className={
          widthClass[6]
        }>Total votes</div>
      </div>
    </div>
  );
};

export default HeaderList;