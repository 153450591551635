import React from 'react';
import classnames from 'classnames';

import { periods } from './config';

const Period = ({ period, onChange }) => {

  return (
    <div className='flex h-14 bg-dark rounded-lg'>
      {
        periods
          .map(({ label, value }) => (
            <button
              type="button"
              onClick={() => onChange(value)}
              key={value}
              disabled={value === period}
              className={
                classnames(
                  'group flex items-center justify-center flex-1 sm:w-20 lg:w-24 border-solid border-2 border-dark rounded-lg font-medium text-center cursor-pointer hover:bg-[#424242]/30 transition text-opacity-60 disabled:pointer-events-none disabled:bg-[#424242]'
                )
              }
            >
              <span className=''>{label}</span>
            </button>
          ))
      }
    </div >
  );
};

export default Period;