const convertWeekDaysIndex = (index) => {
  if (index === 0) return 6;

  return index - 1;
}

export const getWeeks = (data) => {
  const today = new Date();
  const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);

  let day = yesterday;
  const res = [];

  if (day.getDay() > 0) {
    day = new Date(day.getTime() - day.getDay() * 24 * 60 * 60 * 1000)
  }

  for (let i = 0; i < 13; i++) {
    const time = day.getTime();
    let startDayIndex = convertWeekDaysIndex(day.getDay());

    const weekStart = new Date(time - startDayIndex * 24 * 60 * 60 * 1000);
    const endWeek = new Date(weekStart.getTime() + 6 * 24 * 60 * 60 * 1000);

    const startString = new Date(weekStart.toISOString().split('T')[0]);
    const endString = new Date(endWeek.toISOString().split('T')[0]);

    const days = data.filter(i => {
      const itemTime = new Date(i.created_at).getTime();

      return itemTime >= startString.getTime() && itemTime <= endString.getTime();
    });

    const weekData = days.reduce((acc, cur, index) => {
      const shown_scam = cur.shown_scam + (acc?.shown_scam || 0);

      const active_user = index === days.length - 1 ? cur.active_user : (acc?.active_user || 0);

      const total_projects = index === days.length - 1 ? cur.total_projects : (acc?.total_projects || 0);

      const total_scam_database = index === days.length - 1 ? cur.total_scam_database : (acc?.total_scam_database || 0);

      const total_shown_scam = index === days.length - 1 ? cur.total_shown_scam : (acc?.total_shown_scam || 0);

      const total_votes = index === days.length - 1 ? cur.total_votes : (acc?.total_votes || 0);

      const voted_patrol = cur.voted_patrol + (acc?.voted_patrol || 0);

      const created_at = i === 0 ? 'Last week' : String(i) + ' weeks ago';

      return {
        shown_scam,
        active_user,
        total_projects,
        total_scam_database,
        total_shown_scam,
        total_votes,
        voted_patrol,
        created_at
      };
    }, {});

    res.push(weekData);

    day = new Date(weekStart.getTime() - 7 * 24 * 60 * 60 * 1000);
  }

  return res.reverse();
};

export const getMonths = (data) => {
  const today = new Date();
  const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);

  let day = yesterday;

  if (today.getMonth() === day.getMonth()) {
    day = new Date(day.getFullYear(), day.getMonth(), 0);
  }

  day = new Date(yesterday.toISOString().split('T')[0]);

  const res = [];

  for (let i = 0; i < 12; i++) {
    const firstDay = new Date(day.getFullYear(), day.getMonth(), 1);
    const lastDay = new Date(day.getFullYear(), day.getMonth() + 1, 0);

    const days = data.filter(i => new Date(i.created_at) >= firstDay && new Date(i.created_at) <= lastDay);

    const yearData = days.reduce((acc, cur, index) => {
      const shown_scam = cur.shown_scam + (acc?.shown_scam || 0);

      const active_user = index === days.length - 1 ? cur.active_user : (acc?.active_user || 0);

      const total_projects = index === days.length - 1 ? (cur?.total_projects || 0) : (acc?.total_projects || 0);

      const total_scam_database = index === days.length - 1 ? (cur?.total_scam_database || 0) : (acc?.total_scam_database || 0);

      const total_shown_scam = index === days.length - 1 ? (cur?.total_shown_scam || 0) : (acc?.total_shown_scam || 0);

      const total_votes = index === days.length - 1 ? cur.total_votes : (acc?.total_votes || 0);

      const voted_patrol = cur.voted_patrol + (acc?.voted_patrol || 0);

      const created_at = i === 0 ? 'Last month' : String(i) + ' months ago';

      day = new Date(firstDay.getFullYear(), firstDay.getMonth() - 1, 1);

      return {
        shown_scam,
        active_user,
        total_projects,
        total_scam_database,
        total_shown_scam,
        total_votes,
        voted_patrol,
        created_at
      };
    }, {});

    res.push(yearData);
  }

  return res.reverse();
};