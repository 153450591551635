const widthClass = [
  'flex-shrink-0 md:w-[90px] xl:w-[103px]',
  'flex-shrink-0 md:w-[100px] xl:w-[126px]',
  'flex-shrink-0 md:w-[95px] xl:w-[107px]',
  'flex-1 md:w-[170px] xl:w-[205px] overflow-auto',
  'flex-shrink-0 md:w-[70px] xl:w-[96px]',
  'flex-shrink-0 md:w-[60px] xl:w-[67px]',
  'flex-shrink-0 md:w-[90px] xl:w-[112px]',
];

export default widthClass;