import React, { useEffect, useState } from 'react';

import HeadSection from '@/components/common/Head';

import Header from '@/components/Header';
import Footer from '@/components/Footer';

import Top from '@/sections/live/Top';

import List from '@/sections/live/List'
import { voteStatuses, projectStatuses } from '@/sections/live/Filters/config';

const LivePage = () => {
  const [searchParameters, setSearchParameters] = useState({
    count: 400,
    votes: voteStatuses[0].value,
    statuses: projectStatuses[0].value,
  });

  useEffect(() => {
    document.body.classList.add('dark');

    return () => {
      document.body.classList.remove('dark');
    };
  }, []);

  return (
    <div className='bg-[#F8F8F8] dark:bg-body-dark'>
      <Header />

      <main className="pt-20 sm:pt-32">
        <div className="fluid-container">
          <div className='max-w-[1100px] mx-auto'>
            <div className='xl:flex items-center xl:space-x-14'>
              <h1 className='mb-1 sm:mb-0 text-6xl md:leading-tight md:text-[94px] font-bold'>Scam stream</h1>
              <p className='mb-2 xl:mb-0 xl:relative xl:top-2 text-lg md:text-[28px] text-black opacity-40 dark:text-white font-bold xl:w-[190px] leading-tight'>Patrol's latest activity feed</p>
            </div>

            <div className="relative z-[2] mt-6 sm:mt-0 mb-4">
              <Top
                setSearchParameters={setSearchParameters}
                searchParameters={searchParameters}
              />
            </div>

            <div className="">
              <List params={searchParameters} />
            </div>
          </div>
        </div>
      </main>

      <Footer />

    </div>
  );
};

export default LivePage;

export const Head = () => <HeadSection title="Scam stream" />;