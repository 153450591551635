import React, { useState, useMemo, useEffect } from 'react';
import client from '@/helpers/apiClient';
import classnames from 'classnames';

import { parseData, convertToK } from '@/helpers/utils';

import Layout from './Layout';

import { periods } from '../Filters/config';
import { getWeeks, getMonths } from './getDataPeriod';

const defaultDataset = {
  type: 'line',
  fill: false,
  borderJoinStyle: 'round',
  borderWidth: 1.5,
  borderColor: 'rgba(0,0,0,0)',
  pointRadius: 0,
  tension: 0.4
};

const categories = {
  active_user: {
    label: 'Active beta users',
    borderColor: '#3983F7',
    yAxisID: 'y',
  },
  total_scam_database: {
    label: 'Total scam in Database',
    borderColor: '#FF7138',
    yAxisID: 'y1',
  },
  voted_patrol: {
    label: 'Voted from Patrol',
    borderColor: '#C9E84A',
    yAxisID: 'y2',
  },
  // TODO: hide scam statistic
  shown_scam: {
    label: 'Shown scam',
    borderColor: '#D62839',
    yAxisID: 'y3',
  },
};

const getDays = (days = 1) => days * 24 * 60 * 60 * 1000;

const Chart = ({ params }) => {
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState([]);
  const [statistics, setStatistics] = useState(null);

  const periodData = useMemo(() => {
    if (!data?.length) return [];

    // days
    if (params === periods[0].value)
      return data.filter(i => parseData(i.created_at) > new Date().getTime() - getDays(30));

    // week
    if (params === periods[1].value) {
      return getWeeks(data);
    }

    // month
    if (params === periods[2].value)
      // group by month for 1 year and get last 12 months
      return getMonths(data);
    // return data.filter(i => parseData(i.created_at) > new Date().getTime() - 30 * 24 * 60 * 60 * 1000);

    return data;
  }, [data, params]);

  const datasets = useMemo(() => {
    return Object.entries(categories)
      .filter(([key, value]) => selected ? key === selected : true)
      .map(([key, value]) => ({
        ...defaultDataset,
        data: periodData.map(i => ({
          [value.yAxisID]: i[key],
          x: i.created_at
        })),
        parsing: {
          yAxisKey: [value.yAxisID]
        },
        ...value
      }));
  }, [selected, periodData]);

  const switchBar = (key) => {
    setSelected(state => state === key ? null : key)
  };

  useEffect(() => {
    client.get('app-statistics')
      .then(({ data }) => {
        const res = data.map(i => i.indicators)
          .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

        const arrayRes = [];

        const lastDate = new Date(res[0].created_at).getTime();

        if (res.length < 365) {
          for (let i = 1; i < (365 - res.length - 1); i++) {
            const time = lastDate - i * 24 * 60 * 60 * 1000;

            const created_at = new Date(time).toISOString().split('T')[0];
            arrayRes.unshift({
              total_scam_database: 0,
              shown_scam: 0,
              voted_patrol: 0,
              active_user: 0,
              created_at
            });
          }
        }

        arrayRes.push(...res);

        arrayRes.splice(0, arrayRes.length - 370);

        setData(arrayRes);
      });
  }, []);

  useEffect(() => {
    client.get('app-statistics?last_day=true')
      .then(({ data: data2 }) => {
        setStatistics({
          ...data2.indicators,
          shown_scam: data2.indicators.total_shown_scam,
          voted_patrol: data2.indicators.total_votes,
        });
      });
  }, []);

  return (
    <>
      <Layout
        datasets={datasets}
      />

      <div className='pt-4 px-4'>
        <div className='flex flex-wrap sm:items-center justify-between'>
          {
            statistics && Object.entries(categories).map(([key, value]) => (
              <div
                key={key}
                className={
                  classnames(
                    'h-16 sm:h-auto mb-2 sm:mb-0 w-1/2 sm:w-fit flex sm:block cursor-pointer hover:opacity-80 transition',
                    selected && (selected === key ? 'opacity-100' : 'opacity-30 hover:opacity-100 ')
                  )
                }
                onClick={() => switchBar(key)}>
                <div
                  className='mr-2 sm:mr-0 sm:mb-1 w-1 sm:w-10 sm:h-1 rounded-sm'
                  style={{ backgroundColor: value.borderColor }}
                ></div>
                <div className='self-center sm:flex items-center text-sm font-bold'>
                  <div className=''>{convertToK(statistics[key])}</div>
                  <div className='sm:ml-1 opacity-60'>{value.label}</div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
};

export default Chart;